<template>
  <div class="interior-package-box-wrapper">
    <div class="interior-package-title"> {{ packageTitle }}</div>
    <div class="interior-package-subtitle"> {{ packageSubtitle }}</div>
    <div class="terms-description-item" v-for="(service, index) in services">
      <ul class="">
        <li class="service-list"> {{ $t(service.serviceTitle) }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "InteriorPackageBox",
  props: {
    packageTitle: {
      type: String,
      default: ''
    },
    packageSubtitle: {
      type: String,
      default: ''
    },
    serviceTitle: {
      type: String,
      default: ''
    },
    services: {
      type: Array,
      required: true
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";

.interior-package-box-wrapper {
  border-radius: 20px;
  box-shadow: 0px 4px 59px 0px #00000021;
  padding: $spacing-5;
}

.interior-package-title, .interior-package-subtitle {
  color: $color-orange-main;
  text-transform: uppercase;
  margin-bottom: $spacing-1;
}

.interior-package-title {
  @include title-h6;

}

.interior-package-subtitle {
  @include subtitle-2;
}

ul {
  margin: 0;
  padding-left: $spacing-2;
}

.service-list {
  @include body-2;
  margin: 0;
}
</style>
