<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div id="main-container" class="" v-if="serviceName">
    <div class="moving-cleaning-title container">
      {{ translate('moving-heading-title') }}
    </div>
    <div class="moving-cleaning-heading-banner"
         :style="{ 'background-image': 'url(/static/interior-heading-image.png' }">
      <div class="container">
        <div class="  d-flex justify-content-center">
          <div class="heading-content-container">
            <div class="moving-cleaning-subtitle"> {{ translate('subtitle') }}</div>
            <div class="moving-cleaning-description ">{{ translate('description') }}</div>
            <div class=" d-flex justify-content-center">
              <a href="#contact-form">
                <Button :theme="'solid-orange'" :size="'medium'" :text="translate('request-service')"/>
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="our-services-wrapper d-flex justify-content-center ">
      <div class="col-md-7">
        <div class="moving-cleaning-description text-center"> {{ translate('our-services-description') }}</div>
        <div class="package-box-wrapper" v-for="(item, index) in packages" :key="index">
          <InteriorPackageBox
              :packageTitle="$t(item.title)"
              :packageSubtitle="$t(item.subtitle)"
              :services="item.services"
          />
        </div>
        <div class="contacts-right-wrapper" id="contact-form">
          <div class="contact-title"> {{ $t('send-request') }}</div>
          <div class="row ">
            <div class="col-md-4 input-items">
              <InputField
                  :inputtype="'text'"
                  v-model="form.name"
                  :content="form.name"
                  :label="$t('your-name')"
                  :error="fieldErrors.includes('name')"
              />
            </div>
            <div class="col-md-4 input-items">
              <InputField
                  :inputtype="'text'"
                  v-model="form.phone"
                  :content="form.phone"
                  :label="$t('your-number')"
                  :error="fieldErrors.includes('phone')"
              />
            </div>
            <div class="col-md-4 input-items">
              <InputField
                  :inputtype="'text'"
                  v-model="form.email"
                  :content="form.email"
                  :label="'Email'"
                  :error="fieldErrors.includes('email')"
              />
            </div>
          </div>
          <div class="row input-items text-area">
              <TextArea
                  :label="$t('your-message')"
                  :content="form.message"
                  v-model="form.message"
                  :error="fieldErrors.includes('message')"
              />
          </div>
          <div class="col-md-4 button-send">
            <Button :text="$t('send')" @click="sendMessage()" :size="'medium'" :theme="'solid-orange'"/>
          </div>
        </div>
      </div>
    </div>
    <div class="process-wrapper d-flex justify-content-center ">
      <div class="col-md-7">
        <div class="process-title"> {{ translate('whats-the-process-title') }}</div>
        <div class="process-stages">
          <ul class="interior-stage-wrapper">
            <div class="interior-stage-title">{{ $t('interior-stage-1-title') }}</div>
            <li class="interior-stage-item"> {{ $t('interior-stage-1-item-1') }}</li>
            <li class="interior-stage-item"> {{ $t('interior-stage-1-item-2') }}</li>
            <li class="interior-stage-item"> {{ $t('interior-stage-1-item-3') }}</li>
          </ul>
          <ul class="interior-stage-wrapper">
            <div class="interior-stage-title">{{ $t('interior-stage-2-title') }}</div>
            <li class="interior-stage-item"> {{ $t('interior-stage-2-item-1') }}</li>
          </ul>
          <ul class="interior-stage-wrapper">
            <div class="interior-stage-title">{{ $t('interior-stage-3-title') }}</div>
            <li class="interior-stage-item"> {{ $t('interior-stage-3-item-1') }}</li>
            <li class="interior-stage-item"> {{ $t('interior-stage-3-item-2') }}</li>
            <li class="interior-stage-item"> {{ $t('interior-stage-3-item-3') }}</li>
            <li class="interior-stage-item"> {{ $t('interior-stage-3-item-4') }}</li>
            <li class="interior-stage-item"> {{ $t('interior-stage-3-item-5') }}</li>
          </ul>
        </div>
        <div class="process-specifics">
          <ol>
            <li class="specifics-title"> {{ $t('interior-process-specifics-1-title') }}
              <ul>
                <li class="specifics-item"> {{ $t('interior-process-specifics-1-item-1') }}</li>
              </ul>
            </li>
            <li class="specifics-title"> {{ $t('interior-process-specifics-2-title') }}</li>
            <li class="specifics-title"> {{ $t('interior-process-specifics-3-title') }}
              <ul>
                <li class="specifics-item">{{ $t('interior-process-specifics-3-item-1') }}</li>
                <li class="specifics-item">{{ $t('interior-process-specifics-3-item-2') }}</li>
                <li class="specifics-item">{{ $t('interior-process-specifics-3-item-3') }}</li>
                <li class="specifics-item">{{ $t('interior-process-specifics-3-item-4') }}</li>
                <li class="specifics-item">{{ $t('interior-process-specifics-3-item-5') }}</li>
              </ul>
            </li>
            <li class="specifics-title"> {{ $t('interior-process-specifics-4-title') }}
              <ul>
                <li class="specifics-item">{{ $t('interior-process-specifics-4-item-1') }}</li>
              </ul>
            </li>
            <li class="specifics-title" v-if="false"> {{ $t('interior-process-specifics-5-title') }}
              <ul>
                <li class="specifics-item">{{ $t('interior-process-specifics-5-item-1') }}</li>
                <li class="specifics-item">{{ $t('interior-process-specifics-5-item-2') }}</li>
                <li class="specifics-item">{{ $t('interior-process-specifics-5-item-3') }}</li>
                <li class="specifics-item">{{ $t('interior-process-specifics-5-item-4') }}</li>
                <li class="specifics-item">{{ $t('interior-process-specifics-5-item-5') }}</li>
                <li class="specifics-item">{{ $t('interior-process-specifics-5-item-6') }}</li>
                <li class="specifics-item">{{ $t('interior-process-specifics-5-item-7') }}</li>
                <li class="specifics-item">{{ $t('interior-process-specifics-5-item-8') }}</li>
                <li class="specifics-item">{{ $t('interior-process-specifics-5-item-9') }}</li>
              </ul>
            </li>
          </ol>
        </div>
      </div>

    </div>

  </div>
</template>
